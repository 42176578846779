<script setup>
const props = defineProps({
  name: {
    type: String,
    default: 'help'
  },
  size: {
    type: String,
    default: 's'
  }
});

const iconSprite = ref(null);

onMounted(async () => {
  iconSprite.value = await import('@/assets/iconsprite.svg');
});

</script>

<template>
  <i class="ui-icon" v-bind:class="[`ui-icon--size-${props.size}`]" v-if="iconSprite">
    <svg viewBox="0 0 48 48">
      <use v-bind:xlink:href="`${iconSprite.default}#${props.name}`"></use>
    </svg>
  </i>
</template>

<style lang="scss">
.ui-icon {
  --s-size: var(--s-icon-size--m);

  display: inline-block;
  width: var(--s-size);
  height: var(--s-size);
}

.ui-icon--size-s {
  --s-size: var(--s-icon-size--s);
}

.ui-icon--size-l {
  --s-size: var(--s-icon-size--l);
}

.ui-icon--size-xl {
  --s-size: var(--s-icon-size--xl);
}
</style>
