<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'primary' // primary, secondary, text, inline
  },
  size: {
    type: String,
    default: 'major' // minor, major, hero
  },
  icon: {
    type: String,
    default: undefined
  },
  iconOnly: {
    type: Boolean,
    default: false
  },
  expandable: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const iconHref = computed(() => {
  return `/iconsprite.svg#${this.name}`
});

</script>

<template>
  <button 
    class="ui-button" 
    v-bind:class="[
      `ui-button--${props.type}`, 
      `ui-button--${props.size}`, 
      {
        'ui-button--icon': props.iconOnly,
        'ui-button--expandable': props.expandable,
        'ui-button--disabled': props.disabled,
        'ui-button--loading': props.isLoading
      }
    ]"
    v-bind:disabled="disabled"
    v-bind="$attrs"
  >
    <UiIcon class="ui-button__icon" v-bind:name="props.icon" v-if="props.icon" />
    <span class="ui-button__label">
      <slot />
    </span>
    <UiIcon class="ui-button__expander-icon" name="chevron-down" v-if="props.expandable" />
    <UiSpinner class="ui-button__spinner" v-bind:type="props.type === 'primary' ? 'inverted' : 'normal'" v-if="isLoading" />
  </button>
</template>

<style lang="scss">
.ui-button {
  --s-bezel-x: var(--s-button-bezel-x);
  --s-bezel-y: var(--s-button-bezel-y);
  --s-border: var(--s-border-width);
  --s-icon: var(--s-icon-size--m);
  --s-icon-stack: calc(var(--s-icon-inline-stack) * 2);
  --s-focus-width: var(--s-outline-width);
  --s-expandable-bezel-x: var(--s-button-expandable-bezel-x);
  --s-expandable-icon-bezel-x: var(--s-button-expandable-icon-bezel-x);
  --s-spinner: var(--s-spinner-size);

  --c-border: var(--c-button-inverted-background);
  --c-border-hover: var(--c-button-hover-background);
  --c-background: var(--c-button-inverted-background);
  --c-background-hover: var(--c-button-inverted-hover-background);
  --c-text: var(--c-button-inverted);
  --c-text-hover: var(--c-button-inverted-hover);
  --c-focus: var(--c-interaction-focus);

  position: relative;
  padding: calc(var(--s-bezel-y) - var(--s-border)) calc(var(--s-bezel-x) - var(--s-border));
  background-color: var(--c-background);
  background-image: linear-gradient(
    var(--c-background) 50%,
    var(--c-background-hover) 50%
  );
  background-size: 100% 200%;
  border: var(--s-border-width) solid var(--c-border);
  color: var(--c-text);
  font-family: inherit;
  cursor: pointer;

  transition: 
    background-color 200ms linear,
    background-position 210ms cubic-bezier(0.16, 1, 0.3, 1),
    border-color 100ms linear 0ms;
}

.ui-button--secondary {
  --c-border: var(--c-button-default);
  --c-border-hover: var(--c-button-hover);
  --c-background: var(--c-button-background);
  --c-background-hover: var(--c-button-hover-background);
  --c-text: var(--c-button-default);
  --c-text-hover: var(--c-button-hover);
}

.ui-button--text {
  --c-border: transparent;
  --c-background: var(--c-button-background);
  --c-text: var(--c-button-default);
  --c-text-hover: var(--c-button-hover);
  --c-background-hover: var(--c-button-hover-background);
}

.ui-button--inline,
.ui-button--minor.ui-button--inline {
  --s-bezel-x: var(--s-button-inline-bezel-x);
  --s-bezel-y: var(--s-button-inline-bezel-y);
  --c-border: transparent;
  --c-background: var(--c-button-background);
  --c-text: var(--c-button-default);
  --c-text-hover: var(--c-button-hover);
  --c-background-hover: var(--c-button-hover-background);
}

.ui-button--icon {
  --s-bezel-x: var(--s-icon-button-bezel-x);
  --s-bezel-y: var(--s-icon-button-bezel-y);
}

.ui-button--minor {
  --s-bezel-x: var(--s-button-bezel-x-minor);
  --s-bezel-y: var(--s-button-bezel-y-minor);
  --s-focus-width: var(--s-outline-width-minor);
  --s-icon: var(--s-icon-size--s);
  --s-icon-stack: calc(var(--s-icon-inline-stack) * 1.25);
  --s-expandable-bezel-x: var(--s-button-expandable-bezel-x-minor);
  --s-expandable-icon-bezel-x: var(--s-button-expandable-icon-bezel-x-minor);
}

.ui-button--hero {
  --s-icon: var(--s-icon-size--m);
}

.ui-button--primary.ui-button--disabled {
  --c-border: var(--c-button-disabled);
  --c-background: var(--c-button-disabled);
}

.ui-button--secondary.ui-button--disabled {
  --c-border: var(--c-button-disabled);
  --c-text: var(--c-button-disabled);
}

.ui-button--text.ui-button--disabled {
  --c-text: var(--c-button-disabled);
}

.ui-button--minor.ui-button--icon {
  --s-bezel-x: var(--s-icon-button-bezel-x-minor);
  --s-bezel-y: var(--s-icon-button-bezel-y-minor);
  --s-icon-stack: calc(var(--s-icon-inline-stack) * 2);
}

.ui-button--icon.ui-button--expandable {
  --s-expandable-bezel-x: var(--s-button-expandable-icon-only-bezel-x);
  --s-expandable-icon-bezel-x: var(--s-button-expandable-icon-only-icon-bezel-x);
}

.ui-button--minor.ui-button--icon.ui-button--expandable {
  --s-expandable-bezel-x: var(--s-button-expandable-icon-only-bezel-x-minor);
  --s-expandable-icon-bezel-x: var(--s-button-expandable-icon-only-icon-bezel-x-minor);
}

.ui-button--hero.ui-button--icon {
  --s-bezel-x: var(--s-button-bezel-x-hero);
  --s-bezel-y: var(--s-button-bezel-x-hero);
  --s-icon: var(--s-icon-size--l);

  border-radius: 50%;
}

.ui-button:not(.ui-button--disabled):hover {
  background-position: 50% 100%;
  color: var(--c-text-hover);
  border-color: var(--c-border-hover);
  transition: 
    background-color 200ms linear,
    background-position 210ms cubic-bezier(0.16, 1, 0.3, 1),
    border-color 100ms linear 0ms;
}

.ui-button:focus {
  outline: var(--s-focus-width) solid var(--c-focus);
  z-index: 1;
}

.ui-button__icon,
.ui-button__expander-icon {
  width: var(--s-icon);
  height: var(--s-icon);
}

.ui-button__icon {
  vertical-align: middle;
}

.ui-button__label {
  @include button;

  & {
    vertical-align: middle;
  }
}

.ui-button:not(.ui-button--icon) .ui-button__icon + .ui-button__label {
  margin-left: var(--s-icon-stack);
}

.ui-button--expandable .ui-button__label {
  padding-right: var(--s-expandable-bezel-x);
}

.ui-button--icon.ui-button--expandable .ui-button__label {
  padding-right: var(--s-expandable-bezel-x);
}

.ui-button__expander-icon {
  position: absolute;
  top: 50%;
  right: var(--s-expandable-icon-bezel-x);
  margin: calc(var(--s-icon) * -0.5) 0;
}

.ui-button--icon.ui-button--expandable .ui-button__expander-icon {
  right: calc(var(--s-expandable-icon-bezel-x) * 0.33);
}

.ui-button--disabled {
  cursor: not-allowed;
}

.ui-button--loading .ui-button__label {
  opacity: 0;
}

.ui-button__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: calc(-0.5 * var(--s-spinner));
}
</style>
